import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CreateShoppingList } from './pages/CreateShoppingList';
import { MainNav } from './components/MainNav';
import { ViewShoppingLists } from './pages/ViewShoppingLists';
import { AddNewMeal } from './pages/AddNewMeal';
import { Login } from './pages/Login';
import { SelectedShoppingList} from './pages/SelectedShoppingList'
import { Entry } from './pages/Entry';

function App() {
  return (
    <Router>
      <MainNav />
      <Routes>
        <Route path="/" element={<Entry />}/>
        <Route path="/create-shopping-lists" element={<CreateShoppingList />}/>
        <Route path="/view-shopping-lists" element={<ViewShoppingLists />}/>
        <Route path="/add-new-meal" element={<AddNewMeal />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/selected-shopping-list" element={<SelectedShoppingList />}/>
        <Route path="*" element={<h1>Page Not found</h1>}/>
      </Routes>
    </Router>
  );
}

export default App;
