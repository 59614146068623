
import { borderBottom, positions, width } from '@mui/system';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { red } from '@mui/material/colors';
import zIndex from '@mui/material/styles/zIndex';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';


export const MainNav = () => {


    return(
    <div className="App">
        <Navbar style={{ backgroundColor: '#e8e8e8'}} key='md' expand='md' className="mb-3">
        <Container fluid>
            <ShoppingCartIcon className='ms-3' fontSize='large'/>
            <Navbar.Brand className="" href="https://shopping.shanil.com"><h2>Shopping App</h2></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-'md'`} />
            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-'md'`}
            aria-labelledby={`offcanvasNavbarLabel-expand-'md'`}
            placement="end"
            style={{width:'60%'}}
            >
            <Offcanvas.Header closeButton className="justify-content-end">
                
            </Offcanvas.Header>


            <Offcanvas.Header>
                
            <Offcanvas.Title style={{borderBottom: '1px solid grey', width:'100%'}} className='mt-5 ms-3' id={`offcanvasNavbarLabel-expand-'md'`}>
                Menu
                </Offcanvas.Title>
                </Offcanvas.Header>
            <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-1 ms-3 mt-2">
                <Nav.Link className='mt-4' style={{borderBottom: '1px solid #dbdbdb'}} href="/create-shopping-lists">Create new shopping list</Nav.Link>
                <Nav.Link className='mt-4' style={{borderBottom: '1px solid #dbdbdb'}} href="/view-shopping-lists">View shopping lists</Nav.Link>
                <Nav.Link className='mt-4' style={{borderBottom: '1px solid #dbdbdb'}} href="/add-new-meal">Add new meal</Nav.Link>
                {/* <Nav.Link href="/login">Login</Nav.Link> */}
                </Nav>
            </Offcanvas.Body>


            <Offcanvas.Header>
                
                <Offcanvas.Title style={{fontSize: '1rem', position: 'absolute', bottom:'0.3rem', right:'0.8rem', color:'#a1a1a1'}} id={`offcanvasNavbarLabel-expand-'md'`}>
                    Made by Shanil
                    </Offcanvas.Title>
                    </Offcanvas.Header>
            </Navbar.Offcanvas>


        </Container>
        </Navbar>
    </div>    
    );
};