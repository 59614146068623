import "bootstrap/dist/css/bootstrap.min.css"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from "react";
import Button from '@mui/material/Button';
import Axios from "axios";
import { useEffect } from "react";
import React from "react";
import Slide from '@mui/material/Slide';
import { display, fontSize, maxWidth, Stack } from "@mui/system";
import Grid from '@mui/material/Unstable_Grid2';
import {Routes, Route, useNavigate} from 'react-router-dom';

export const Entry = () => {

    const navigate = useNavigate();
    const [openLoginDialog, setOpenLoginDialog] = useState(false)
    const handleLoginClose = (event, reason) => {
      console.log(reason)
      if (reason && reason == "backdropClick"){
        return;
      }

        setOpenLoginDialog(false);
      };



      useEffect(() => {
        // openLoginDialog()
        setOpenLoginDialog(true)
    }, []);


    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });

    const CreateShoppingList = () => {
      navigate('/create-shopping-lists')
    };
    
    const ViewShoppingList = () => {
      navigate('/view-shopping-lists')
    };
    
    const CreateMeal = () => {
      navigate('/add-new-meal')
    };

    return(
        <Dialog
        open={openLoginDialog}
        TransitionComponent={Transition}
        onClose={handleLoginClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'lg'}
        fullWidth={true}
        keepMounted={false}
        align={'center'}
      >
        <DialogTitle style={{fontSize:'1.5rem'}} id="alert-dialog-title">
          Choose an option
        </DialogTitle>
        <DialogContent>

          <Grid className="pt-5 pb-5" container spacing={2}>
              <Grid xs={12} sm={4} md={4} lg={4}>
              <Button onClick={CreateShoppingList} style={{minWidth:'14rem', maxWidth:'15rem', backgroundColor: '#ebebeb', color:'#616161', fontSize:'1rem'}} className="pt-5 pb-5" variant="contained">Create Shopping List</Button>
              </Grid>
              <Grid xs={12} sm={4} md={4} lg={4}>
              <Button onClick={ViewShoppingList} style={{minWidth:'14rem', maxWidth:'15rem', backgroundColor: '#ebebeb', color:'#616161', fontSize:'1rem'}} className="pt-5 pb-5" variant="contained">View Shopping Lists</Button>
              </Grid>         
              <Grid xs={12} sm={4} md={4} lg={4}>
              <Button onClick={CreateMeal} style={{minWidth:'14rem', maxWidth:'15rem', backgroundColor: '#ebebeb', color:'#616161', fontSize:'1rem'}} className="pt-5 pb-5" variant="contained">Create New Meals</Button>
              </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );


    
};