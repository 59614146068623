import "bootstrap/dist/css/bootstrap.min.css"
import Form from 'react-bootstrap/Form';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import "react-datetime/css/react-datetime.css";
import "react-datepicker/dist/react-datepicker.css";
import Grid from '@mui/material/Unstable_Grid2';
import { useState, useEffect} from "react";
import Axios from 'axios';
import CreateIcon from '@mui/icons-material/Create';
import Button from '@mui/material/Button';
import "bootstrap/dist/css/bootstrap.min.css"
import Container from 'react-bootstrap/Container';
import "react-datetime/css/react-datetime.css";
import "react-datepicker/dist/react-datepicker.css";
import {} from 'react-places-autocomplete';
import TextField from '@mui/material/TextField'
import AddIcon from '@mui/icons-material/Add';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DoneIcon from '@mui/icons-material/Done';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useReducer } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Icon } from "@mui/material";
import IconButton from '@mui/material/IconButton';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }



const ITEM_HEIGHT = 170
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        //maxHeight: '40rem',
        //width: 250,
      },
    },
  };  

export const CreateShoppingList = () => {
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const tempHome = [];
    const tempFruit = [];
    const tempMeat = [];
    const tempDairy = [];
    const tempBaking = [];
    const tempPantry = [];
    const tempCoffee = [];
    const tempDrinks = [];
    const tempFrezzer = [];
    const tempCrisp = [];
    const tempCleaning = [];
    const tempToil = [];
    const tempLunch = [];
    const tempDinner = [];



    
    const cardHeight = "300px";
    const [personName, setPersonName] = useState([]);
    const [mealList, setMealList] = useState([]);
    const [selectedMealList, setSelectedMealList] = useState([]);
    const [ingredientsList, setIngredientList] = useState([]);
    const [inputShoppingListName, setInputShoppingListName] = useState("");
    const [ingredientInput, setIngredientInput] = useState("");
    const [inputQuantity, setInputQuantity] = useState("");
    const [type, setType] = useState("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [listAllItems, setListAllItems] = useState([])
    const [isLoadingUpdate, setIsLoadingUpdate] = React.useState(false);
    const [isLoadingLoadbutton, setIsLoadingLoadButton] = React.useState(false);
    const [isLoadingOpenbutton, setIsLoadingOpenButton] = React.useState(false);
    const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
    const [mealFilterOpen, setMealFilterOpen] = React.useState(false);
    const [mealType, setMealType] = useState("")

    const [homeList, setHomeList] = useState([]);


    const [fruitList, setFruitList] = useState([]);
    const [inputFruit, setInputFruit] = useState("");
    const [selectedShoppingList, setSelectedShoppingList] = useState("")
    const [shoppingListNames, setShoppingListNames] = useState([])

    const [meatList, setMeatList] = useState([]);

    const [dairyList, setDairyList] = useState([]);

    const [bakingList, setBakingList] = useState([]);

    const [pantryList, setPantryList] = useState([]);

    const [coffeeList, setCoffeeList] = useState([]);

    const [drinksList, setDrinksList] = useState([]);

    const [freezerList, setFreezerList] = useState([]);

    const [crispList, setCrispList] = useState([]);

    const [cleaningList, setCleaningList] = useState([]);

    const [toilList, setToilList] = useState([]);

    const [lunchList, setLunchList] = useState([]);

    const [dinnerList, setDinnerList] = useState([]);

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });

      const { vertical, horizontal, open } = state;

    const handleChange = (event) => {
        
        setSelectedMealList(event.target.value);
        const {
            target: { value },
        } = event;
        setPersonName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };



      const handleChangeTab = (event, newValue) => {
        setValue(newValue);
        setHomeList([])
        setFruitList([])
        setMeatList([])
        setDairyList([])
        setBakingList([])
        setPantryList([])
        setCoffeeList([])
        setDrinksList([])
        setFreezerList([])
        setCrispList([])
        setCleaningList([])
        setLunchList([])
        setDinnerList([])
        setToilList([])
        setListAllItems([])
        setSelectedShoppingList("")
        setPersonName([])
        setSelectedMealList([])
        setMealType("all")
        setInputShoppingListName("")
        
      };
      
      const handleChangeInputIngredient = (event) => {
        setIngredientInput(event.target.value);
    };
    
    const handleChangeInputQuantity = (event) => {
        setInputQuantity(event.target.value);
    };
    const typeChange = (event) => {
        setType(event.target.value)

    };

    const handleClose = () => {
        setState({ ...state, open: false });
      };

      const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialog = () => {
        setOpenDialog(false);
      };

      const handleClickOpenErrorDialog = () => {
        setOpenErrorDialog(true);
      };
    
      const handleCloseErrorDialog = () => {
        setOpenErrorDialog(false);
      };

      const handleOpenMealFilter = () => {
        setMealFilterOpen(true)
      };

      const handleCloseMealFilter = () => {
        setMealFilterOpen(false)
      };



    //   const Login = () => {
    //     Axios.post('http://localhost:3001/api/login', {
    //         user: 'root',
    //         password: 'root', 
    //         database: 'shoppingappdatabase'
    //     }).then((response) => {
    //         console.log(response.data)


    //         if (response.data === "yes") {
    //             Axios.get('http://localhost:3001/api/getallmeals', {
    //             }).then((response) => {
    //                 response.data.map((val) => {
    //                     setMealList(mealList => [...mealList, val.mealName])            
    //                 })
    //             })
    //             getShoppingListNames()
    //         }
    //     });

    //   };

    const addIngredient= () => {

        if (type === "home"){
            const id = homeList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newHomeList = [...homeList, temp];
            setHomeList(newHomeList)
        }
        if (type === "fruitAndVeg"){
            const id = fruitList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newFruitList = [...fruitList, temp];
            setFruitList(newFruitList)
        }
        if (type === "meats"){
            const id = meatList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newMeatList = [...meatList, temp];
            setMeatList(newMeatList)
        }
        if (type === "dairy"){
            const id = dairyList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newDairyList = [...dairyList, temp];
            setDairyList(newDairyList)
        }
        if (type === "baking"){
            const id = bakingList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newBakingList = [...bakingList, temp];
            setBakingList(newBakingList)
        }
        if (type === "pantry"){
            const id = pantryList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newPantryList = [...pantryList, temp];
            setPantryList(newPantryList)
        }
        if (type === "confectionaries"){
            const id = coffeeList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newCoffeeList = [...coffeeList, temp];
            setCoffeeList(newCoffeeList)
        }
        if (type === "drinks"){
            const id = drinksList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newDrinksList = [...drinksList, temp];
            setDrinksList(newDrinksList)
        }
        if (type === "freezer"){
            const id = freezerList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newFreezerList = [...freezerList, temp];
            setFreezerList(newFreezerList)
        }
        if (type === "Crisps"){
            const id = crispList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newCrispList = [...crispList, temp];
            setCrispList(newCrispList)
        }
        if (type === "cleaning"){
            const id = cleaningList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newCleaningList = [...cleaningList, temp];
            setCleaningList(newCleaningList)
        }
        if (type === "Toiletries"){
            const id = toilList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newToilList = [...toilList, temp];
            setToilList(newToilList)
        }
        if (type === "lunch"){
            const id = lunchList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newLunchList = [...lunchList, temp];
            setLunchList(newLunchList)
        }
        if (type === "dinner"){
            const id = dinnerList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type, is_ticked: 0};
            const newDinnerList = [...dinnerList, temp];
            setDinnerList(newDinnerList)
        }

        setIngredientInput("")
        setInputQuantity("")
        setType("")
    };

    const handleChangeInputShoppingListName = (event) => {
        setInputShoppingListName(event.target.value);
    };

    const mealTypeChange = (event) => {
        const selectedMealTypeVar = event.target.value
        setMealType(event.target.value)

        if (event.target.value === "all") {
            Axios.get('https://shopping.shanil.com/api/getallmeals', {
            }).then((response) =>{
                setMealList([])
                // eslint-disable-next-line
                response.data.map((val) => {
                    setMealList(mealList => [...mealList, val.mealName])            
                })
            })

        } else {
            
        Axios.get('https://shopping.shanil.com/api/getfilteredmeals', {
            params: {selectedMealTypeVar}
        }).then((response) =>{
            console.log(response.data)
            setMealList([])
            // eslint-disable-next-line
            response.data.map((val) => {
                setMealList(mealList => [...mealList, val.mealName])            
            })
        })

        }

    };

    const handleSelectChange = (event) => {
        const selectedShoppingList = event.target.value
        setSelectedShoppingList(event.target.value)



        Axios.get('https://shopping.shanil.com/api/getselectedshoppinglist', {
            params: {selectedShoppingList}
        }).then((response) => {
            setListAllItems(response.data)
            autoUpdateBoxes(response.data)
        
        });
    };

    const onClear = () => {
        setHomeList([])
        setFruitList([])
        setMeatList([])
        setDairyList([])
        setBakingList([])
        setPantryList([])
        setCoffeeList([])
        setDrinksList([])
        setFreezerList([])
        setCrispList([])
        setCleaningList([])
        setToilList([])
        setLunchList([])
        setDinnerList([])
        setIngredientInput("")
        setInputQuantity("")
        setType("")
        setPersonName([])
        setSelectedMealList([])
        setIsLoading(false)
        handleCloseDialog()
        getShoppingListNames()
    };
    

    const autoUpdateBoxes = (data) => {
        //console.log(data)
        setHomeList([])
        setFruitList([])
        setMeatList([])
        setDairyList([])
        setBakingList([])
        setPantryList([])
        setCoffeeList([])
        setDrinksList([])
        setFreezerList([])
        setCrispList([])
        setCleaningList([])
        setLunchList([])
        setDinnerList([])
        setToilList([])
        setListAllItems([])
        data.map((task) => {
         
            var type = task.type
            if (type === "home"){
                const id = tempHome.length + 1;
                const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                tempHome.push(temp);
                setHomeList(tempHome);
            }
            if (type === "fruitAndVeg"){
                const id = tempFruit.length + 1;
                const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                tempFruit.push(temp);
            }
            if (type === "meats"){
                const id = tempMeat.length + 1;
                const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                tempMeat.push(temp);
            }
            if (type === "dairy"){
                const id = tempDairy.length + 1;
                const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                tempDairy.push(temp);
            }
            if (type === "baking"){
                const id = tempBaking.length + 1;
                const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                tempBaking.push(temp);
            }
            if (type === "pantry"){
                const id = tempPantry.length + 1;
                const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                tempPantry.push(temp);
            }
            if (type === "confectionaries"){
                const id = tempCoffee.length + 1;
                const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                tempCoffee.push(temp);
            }
            if (type === "drinks"){
                const id = tempDrinks.length + 1;
                const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                tempDrinks.push(temp);
            }
            if (type === "freezer"){
                const id = tempFrezzer.length + 1;
                const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                tempFrezzer.push(temp);
            }
            if (type === "Crisps"){
                const id = tempCrisp.length + 1;
                const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                tempCrisp.push(temp);
            }
            if (type === "cleaning"){
                const id = tempCleaning.length + 1;
                const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                tempCleaning.push(temp);
            }
            if (type === "Toiletries"){
                const id = tempToil.length + 1;
                const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                tempToil.push(temp);
            }
            if (type === "lunch"){
                const id = tempLunch.length + 1;
                const temp = {id, ingredient:task.ingredients ,quantity:inputQuantity, type:type, is_ticked: 0};
                tempLunch.push(temp);
            }
            if (type === "dinner"){
                console.log("made")
                const id = tempDinner.length + 1;
                const temp = {id, ingredient:task.ingredients ,quantity:inputQuantity, type:type, is_ticked: 0};
                tempDinner.push(temp);
            }
            setFruitList(tempFruit);
            setMeatList(tempMeat);
            setDairyList(tempDairy);
            setBakingList(tempBaking);
            setPantryList(tempPantry);
            setCoffeeList(tempCoffee);
            setDrinksList(tempDrinks);
            setFreezerList(tempFrezzer);
            setCrispList(tempCrisp);
            setCleaningList(tempCleaning);
            setToilList(tempToil);
            setLunchList(tempLunch)
            setDinnerList(tempDinner)
    })
    };



    const loadShoppingList = () => {
        setIsLoadingOpenButton(true)
        setHomeList([])
        setFruitList([])
        setMeatList([])
        setDairyList([])
        setBakingList([])
        setPantryList([])
        setCoffeeList([])
        setDrinksList([])
        setFreezerList([])
        setCrispList([])
        setCleaningList([])
        setToilList([])
        setLunchList([])
        setDinnerList([])
        setListAllItems([])
        Axios.get('https://shopping.shanil.com/api/getselectedshoppinglist', {
            params: {selectedShoppingList}
        }).then((response) => {
            setListAllItems(response.data)
            console.log(response.data)

            response.data.map((task) => {
                
                var type = task.type
                if (type === "home"){
                    const id = tempHome.length + 1;
                    const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                    tempHome.push(temp);
                }
                if (type === "fruitAndVeg"){
                    const id = tempFruit.length + 1;
                    const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                    tempFruit.push(temp);
                }
                if (type === "meats"){
                    const id = tempMeat.length + 1;
                    const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                    tempMeat.push(temp);
                }
                if (type === "dairy"){
                    const id = tempDairy.length + 1;
                    const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                    tempDairy.push(temp);
                }
                if (type === "baking"){
                    const id = tempBaking.length + 1;
                    const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                    tempBaking.push(temp);
                }
                if (type === "pantry"){
                    const id = tempPantry.length + 1;
                    const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                    tempPantry.push(temp);
                }
                if (type === "confectionaries"){
                    const id = tempCoffee.length + 1;
                    const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                    tempCoffee.push(temp);
                }
                if (type === "drinks"){
                    const id = tempDrinks.length + 1;
                    const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                    tempDrinks.push(temp);
                }
                if (type === "freezer"){
                    const id = tempFrezzer.length + 1;
                    const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                    tempFrezzer.push(temp);
                }
                if (type === "Crisps"){
                    const id = tempCrisp.length + 1;
                    const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                    tempCrisp.push(temp);
                }
                if (type === "cleaning"){
                    const id = tempCleaning.length + 1;
                    const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                    tempCleaning.push(temp);
                }
                if (type === "Toiletries"){
                    const id = tempToil.length + 1;
                    const temp = {id, ingredient:task.ingredients, quantity:task.quantity, type:task.type, is_ticked: task.is_ticked.data[0]};
                    tempToil.push(temp);
                }
                if (type === "lunch"){
                    const id = tempLunch.length + 1;
                    const temp = {id, ingredient:task.ingredients ,quantity:inputQuantity, type:type, is_ticked: 0};
                    tempLunch.push(temp);
                }
                console.log("run")
                if (type === "dinner"){
                    const id = tempDinner.length + 1;
                    const temp = {id, ingredient:task.ingredients ,quantity:inputQuantity, type:type, is_ticked: 0};
                    tempDinner.push(temp);
                }
                setHomeList(tempHome);
                setFruitList(tempFruit);
                setMeatList(tempMeat);
                setDairyList(tempDairy);
                setBakingList(tempBaking);
                setPantryList(tempPantry);
                setCoffeeList(tempCoffee);
                setDrinksList(tempDrinks);
                setFreezerList(tempFrezzer);
                setCrispList(tempCrisp);
                setCleaningList(tempCleaning);
                setToilList(tempToil);
                setLunchList(tempLunch);
                setDinnerList(tempDinner);
        })
            
        }).finally(() =>{
            setIsLoadingOpenButton(false)
        });
  };

    
      const [completeList, setCompleteList] = useState([]);

      const onCreate = () => {
        setIsLoadingLoadButton(true)
        var tempp = []
        selectedMealList.map((select) => {
            Axios.get('https://shopping.shanil.com/api/getselectedingredients', {
                params: {select}
            }).then((response) => {
                tempp = [...tempp, response.data]
                setIngredientList(response.data)

                response.data.map((test) => {
                    var type = test.type
                    if (type === "home"){
                        const id = tempHome.length + 1;
                        const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                        tempHome.push(temp);
                    }
                    if (type === "fruitAndVeg"){
                        const id = tempFruit.length + 1;
                        const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                        tempFruit.push(temp);
                    }
                    if (type === "meats"){
                        const id = tempMeat.length + 1;
                        const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                        tempMeat.push(temp);
                    }
                    if (type === "dairy"){
                        const id = tempDairy.length + 1;
                        const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                        tempDairy.push(temp);
                    }
                    if (type === "baking"){
                        const id = tempBaking.length + 1;
                        const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                        tempBaking.push(temp);
                    }
                    if (type === "pantry"){
                        const id = tempPantry.length + 1;
                        const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                        tempPantry.push(temp);
                    }
                    if (type === "confectionaries"){
                        const id = tempCoffee.length + 1;
                        const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                        tempCoffee.push(temp);
                    }
                    if (type === "drinks"){
                        const id = tempDrinks.length + 1;
                        const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                        tempDrinks.push(temp);
                    }
                    if (type === "freezer"){
                        const id = tempFrezzer.length + 1;
                        const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                        tempFrezzer.push(temp);
                    }
                    if (type === "Crisps"){
                        const id = tempCrisp.length + 1;
                        const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                        tempCrisp.push(temp);
                    }
                    if (type === "cleaning"){
                        const id = tempCleaning.length + 1;
                        const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                        tempCleaning.push(temp);
                    }
                    if (type === "Toiletries"){
                        const id = tempToil.length + 1;
                        const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                        tempToil.push(temp);
                    }
                })
            }).finally(() => {
                setIsLoadingLoadButton(false)
            })
        })
        setHomeList(tempHome);
        setFruitList(tempFruit);
        setMeatList(tempMeat);
        setDairyList(tempDairy);
        setBakingList(tempBaking);
        setPantryList(tempPantry);
        setCoffeeList(tempCoffee);
        setDrinksList(tempDrinks);
        setFreezerList(tempFrezzer);
        setCrispList(tempCrisp);
        setCleaningList(tempCleaning);
        setToilList(tempToil);
        
      };

      const deleteIngredient = (id, type) => {
        if (type === "home"){
            setHomeList(homeList.filter((task) => task.id !== id));
        }
        if (type === "fruitAndVeg"){
            setFruitList(fruitList.filter((task) => task.id !== id));
        }
        if (type === "meats"){
            setMeatList(meatList.filter((task) => task.id !== id));
        }
        if (type === "dairy"){
            setDairyList(dairyList.filter((task) => task.id !== id));
        }
        if (type === "baking"){
            setBakingList(bakingList.filter((task) => task.id !== id));
        }
        if (type === "pantry"){
            setPantryList(pantryList.filter((task) => task.id !== id));
        }
        if (type === "confectionaries"){
            setCoffeeList(coffeeList.filter((task) => task.id !== id));
        }
        if (type === "drinks"){
            setDrinksList(drinksList.filter((task) => task.id !== id));
        }
        if (type === "freezer"){
            setFreezerList(freezerList.filter((task) => task.id !== id));
        }
        if (type === "Crisps"){
            setCrispList(crispList.filter((task) => task.id !== id));
        }
        if (type === "cleaning"){
            setCleaningList(cleaningList.filter((task) => task.id !== id));
        }
        if (type === "Toiletries"){
            setToilList(toilList.filter((task) => task.id !== id));
        }
        if (type === "lunch"){
            setLunchList(lunchList.filter((task) => task.id !== id));
        }
        if (type === "dinner"){
            setDinnerList(dinnerList.filter((task) => task.id !== id));
        }
    };


    const getShoppingListNames = ()  => {
        Axios.get('https://shopping.shanil.com//api/getshoppinglists', {
        }).then((response) => {
        setShoppingListNames(response.data);
        })
    };

useEffect(() => {
    setMealType("all")
    Axios.get('https://shopping.shanil.com/api/getallmeals', {
    }).then((response) =>{
        // eslint-disable-next-line
        response.data.map((val) => {
            setMealList(mealList => [...mealList, val.mealName])            
        })
    })
    getShoppingListNames()
}, []);

const onSubmit = () => {
    var completeList = ([...homeList, ...fruitList, ...meatList, ...dairyList, ...bakingList, ...pantryList, ...coffeeList, ...drinksList, ...freezerList, ...crispList, ...cleaningList, ...toilList, ...lunchList, ...dinnerList]);

    setIsLoading(true)
    var shoppingListName = inputShoppingListName
    var completeListArray = completeList.map ((data) => {
        return [
            data.ingredient,
            data.quantity,
            data.type
        ];
    });

    if (completeListArray.length === 0){
        setIsLoading(false)
        handleCloseDialog()
        handleClickOpenErrorDialog()
    }else{
        Axios.post('https://shopping.shanil.com/api/createnewshoppinglist', {
            shoppingListName: shoppingListName,
            completeList: completeListArray
        }).then(() => {
    
        }).finally(() => {
            setState({ open: true, vertical:'bottom', horizontal: 'left' });
            setInputShoppingListName("")
            setHomeList([])
            setFruitList([])
            setMeatList([])
            setDairyList([])
            setBakingList([])
            setPantryList([])
            setCoffeeList([])
            setDrinksList([])
            setFreezerList([])
            setCrispList([])
            setCleaningList([])
            setToilList([])
            setLunchList([])
            setDinnerList([])
            setIngredientInput("")
            setInputQuantity("")
            setType("")
            setPersonName([])
            setSelectedMealList([])
            setIsLoading(false)
            handleCloseDialog()
            getShoppingListNames()
        })

    }


};

const updateShoppingList = () => {
    setIsLoadingUpdate(true)

    var completeList = ([...homeList, ...fruitList, ...meatList, ...dairyList, ...bakingList, ...pantryList, ...coffeeList, ...drinksList, ...freezerList, ...crispList, ...cleaningList, ...toilList, ...lunchList, ...dinnerList]);

    var shoppingListName = inputShoppingListName
    var completeListArray = completeList.map ((data) => {
        return [
            data.ingredient,
            data.quantity,
            data.type,
            data.is_ticked
        ];
    });

        Axios.patch('https://shopping.shanil.com/api/updateshoppinglists', {
            shoppingListName: selectedShoppingList,
            completeList: completeListArray
        }).then(() => {
    
        }).finally(() => {
            setState({ open: true, vertical:'bottom', horizontal: 'left' });
            setInputShoppingListName("")
            setHomeList([])
            setFruitList([])
            setMeatList([])
            setDairyList([])
            setBakingList([])
            setPantryList([])
            setCoffeeList([])
            setDrinksList([])
            setFreezerList([])
            setCrispList([])
            setCleaningList([])
            setToilList([])
            setLunchList([])
            setDinnerList([])
            setIngredientInput("")
            setInputQuantity("")
            setType("")
            setPersonName([])
            setSelectedMealList([])
            setIsLoading(false)
            handleCloseDialog()
            getShoppingListNames()
            setSelectedShoppingList("")
            setIsLoadingUpdate(false)
        })

    

    
};


return (
    <div className="m-3">
        <Row>
            <Col  md={12} lg={4} xl={3}>
            <Form onSubmit={(event) => event.preventDefault()}>
                <h1 className=""></h1>
                <Paper style={{width: "100%"}} elevation={6} sx={{ minWidth: 275, maxWidth: 1000 }}>
                <Box className="p-2" sx={{ width: '100%' }}>
                {value === 0 ? <h3 className="p-2">Create Shopping List</h3> : <h3 className="p-2">Edit Shopping List</h3> }
      <Box sx={{borderTop:1, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label="Create" {...a11yProps(0)} />
          <Tab label="Edit" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      <div className="pb-3 pe-3">
                    <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                        <h3 className="p-2">{inputShoppingListName}</h3>
                        <div className="p-2">
                            <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={12}>
                                            <TextField
                                                onChange={handleChangeInputShoppingListName}
                                                required
                                                id="shoppingList"
                                                name="shoppingList"
                                                label="Shopping List Name"
                                                fullWidth
                                                autoComplete="given-name"
                                                variant="outlined"
                                                value={inputShoppingListName}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={mealType}
                                            onChange={mealTypeChange}
                                            defaultValue="all"
                                        >
                                            <FormControlLabel  value="lunch" control={<Radio />} label="Lunch" />
                                            <FormControlLabel value="dinner" control={<Radio />} label="Dinner" />
                                            <FormControlLabel value="lunchAndDinner" control={<Radio />} label="Lunch and dinner" />
                                            <FormControlLabel value="other" control={<Radio />} label="Other" />
                                            <FormControlLabel value="all" control={<Radio />} label="All" />
                                        </RadioGroup>

                                        </Grid> */}



                                    <Grid xs={12} sm={12} md={12} lg={12}>
                                    <InputLabel id="demo-multiple-chip-label">Meal filter</InputLabel>


                                    <FormControl style={{width: '13rem'}}>
                                            <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    style={{height:'2.5rem'}}
                                                    value={mealType}
                                                    onChange={mealTypeChange}
                                                    open={mealFilterOpen}
                                                    onOpen={handleOpenMealFilter}
                                                    onClose={handleCloseMealFilter}
                                                >
                                            <MenuItem key="lunch" value="lunch">Lunch</MenuItem>
                                            <MenuItem key="dinner" value="dinner">Dinner</MenuItem>
                                            <MenuItem key="lunchAndDinner" value="lunchAndDinner">Lunch and Dinner</MenuItem>
                                            <MenuItem key="other" value="other">Other</MenuItem>
                                            <MenuItem key="all" value="all">All</MenuItem>
                                                </Select>
                                            </FormControl>
                                </Grid>


                                <Grid xs={11} sm={6} md={6} lg={11}>
                                    <InputLabel id="demo-multiple-chip-label">Meals</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={personName}
                                        onChange={handleChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                        >                                        
                                        {mealList.map((mealName) => (
                                            <MenuItem
                                            key={mealName}
                                            value={mealName}
                                            >
                                            {mealName}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={1} sm={6} md={12} lg={1}>
                                    <IconButton className="mt-4" onClick={handleOpenMealFilter}>
                                        <FilterListIcon fontSize="medium"/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container spacing={2}>

                        <Grid className="mt-2" style={{display: "flex", justifyContent: "flex-start"}} item xs={6} sm={6} md={6} lg={6}>
                                            <Button
                                        variant="contained"
                                        onClick={onClear}
                                        disabled={selectedMealList.length === 0 ? true: false}
                                        endIcon={<UploadIcon />}>
                                        Clear meals
                                    </Button>
                                        </Grid>

                        <Grid className="mt-2" style={{display: "flex", justifyContent: "flex-end"}} item xs={6} sm={6} md={6} lg={6}>
                                            <LoadingButton
                                        loading={isLoadingLoadbutton}
                                        variant="contained"
                                        onClick={onCreate}
                                        disabled={selectedMealList.length === 0 ? true: false}
                                        endIcon={<UploadIcon />}>
                                        Load Meals
                                    </LoadingButton>
                                        </Grid>

                                        </Grid>
                    </Box>
                </div>
      </TabPanel>
      <TabPanel value={value} index={1}>


<div className="p-3">
      <Grid xs={12} sm={6} md={12} lg={10}>
                                            <InputLabel id="demo-multiple-chip-label">Select Shopping List</InputLabel>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={selectedShoppingList}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    onChange={handleSelectChange}
                                                >
                                                    {shoppingListNames.map ((item) => {
                                                        return (
                                                            <MenuItem key={item.shopping_list_name} value={item.shopping_list_name}>{item.shopping_list_name}</MenuItem>
                                                        )
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {/* <Grid className="mt-3" style={{display: "flex", justifyContent: "flex-end"}} item xs={12} sm={12} md={12} lg={12}>

                                            <LoadingButton
                                        loading={isLoadingOpenbutton}
                                        variant="contained"
                                        onClick={loadShoppingList}
                                        disabled={selectedShoppingList === "" ? true : false}
                                        endIcon={<UploadIcon />}>
                                        Open Shopping List
                                    </LoadingButton>
                                        </Grid> */}
                                        </div>
      </TabPanel>
    </Box>
            </Paper>
            <Paper style={{width: "100%"}} elevation={6} sx={{ minWidth: 275, maxWidth: 1000 }}>
                        <div className="p-4 mt-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                <div className="p-2">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <h4 className="">Add items</h4>
                                        </Grid>
                                        <Grid item xs={7} sm={8} md={8} lg={6}>
                                            <TextField
                                                onChange={handleChangeInputIngredient}                                        
                                                id="Ingredient"
                                                name="Ingredient"
                                                label="Item"
                                                fullWidth
                                                autoComplete="given-name"
                                                variant="outlined"
                                                value={ingredientInput}
                                            />
                                        </Grid>
                                        <Grid item xs={5} sm={4} md={4} lg={4}>
                                            <TextField     
                                                onChange={handleChangeInputQuantity}                                   
                                                id="Quantity"
                                                name="Quantity"
                                                label="Quantity"
                                                fullWidth
                                                autoComplete="given-name"
                                                variant="outlined"
                                                value={inputQuantity}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={typeChange}
                                            value={type}
                                        >
                                            <FormControlLabel value="home" control={<Radio />} label="Home" />
                                            <FormControlLabel value="fruitAndVeg" control={<Radio />} label="Fruit and Veg" />
                                            <FormControlLabel value="meats" control={<Radio />} label="Meats" />
                                            <FormControlLabel value="dairy" control={<Radio />} label="Dairy" />
                                            <FormControlLabel value="baking" control={<Radio />} label="Baking" />
                                            <FormControlLabel value="pantry" control={<Radio />} label="Pantry Items" />
                                            <FormControlLabel value="confectionaries" control={<Radio />} label="Coffee" />
                                            <FormControlLabel value="drinks" control={<Radio />} label="Drinks" />
                                            <FormControlLabel value="freezer" control={<Radio />} label="Freezer Food" />
                                            <FormControlLabel value="Crisps" control={<Radio />} label="crisps" />
                                            <FormControlLabel value="cleaning" control={<Radio />} label="Cleaning" />
                                            <FormControlLabel value="Toiletries" control={<Radio />} label="toiletries" />
                                            <FormControlLabel value="lunch" control={<Radio />} label="lunch" />
                                            <FormControlLabel value="dinner" control={<Radio />} label="dinner" />
                                        </RadioGroup>

                                        </Grid>
                                        <Grid style={{display: "flex", justifyContent: "flex-end"}} item xs={12} sm={12} md={12} lg={12}>
                                            <Button
                                                onClick={addIngredient}
                                                disabled={(type === "" || ingredientInput === "" || inputQuantity === "") ? true : false}
                                                variant="contained"
                                                 endIcon={<AddIcon />}>
                                                    Add
                                            </Button>
                                        </Grid>
                                        <Grid className="mt-1" style={{display: "flex", justifyContent: "flex-end"}} item xs={12} sm={12} md={12} lg={12}>
                                        {value === 0 ? <Button
                                                variant="contained"
                                                color= "success"
                                                className="p-3"
                                                type="submit"
                                                disabled={inputShoppingListName === "" ? true : false}
                                                onClick={handleClickOpenDialog}
                                                 endIcon={<CreateIcon />}>
                                                    Create Shopping List
                                            </Button> : 
                                        <LoadingButton
                                        loading={isLoadingUpdate}
                                        variant="contained"
                                        color= "success"
                                        onClick={updateShoppingList}
                                        className="p-3"
                                        autoFocus
                                        disabled={selectedShoppingList === "" ? true : false}
                                        endIcon={<CreateIcon />}>
                                        Update shopping list
                                    </LoadingButton>}
                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>
                        </div>
                    </Paper>
            </Form>
            </Col>
            <Col md={12} lg={8} xl={9}>
                <Row>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Home</h3>
                                    <FormGroup>
                                        {homeList.map((item) => {
                                            return(
                                        <div style={{display:'flex'}}>
                                        <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                            <Button
                                            style={{justifyContent:'flex-end'}}
                                                variant="outline"
                                                onClick={() => deleteIngredient(item.id, item.type)}
                                                 endIcon={<DeleteForeverIcon
                                                    sx={{ color: '#d4d1c9' }} />}>
                                            </Button>
                                            </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Fruit and Veg</h3>
                                    <FormGroup>
                                        {fruitList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                    style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                         endIcon={<DeleteForeverIcon
                                                            sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                    </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Meats</h3>
                                    <FormGroup>
                                        {meatList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                    style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                         endIcon={<DeleteForeverIcon
                                                            sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                    </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Dairy</h3>
                                    <FormGroup>
                                        {dairyList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                    style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                         endIcon={<DeleteForeverIcon
                                                            sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                    </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Baking</h3>
                                    <FormGroup>
                                        {bakingList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                    style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                         endIcon={<DeleteForeverIcon
                                                            sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                    </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Pantry</h3>
                                    <FormGroup>
                                        {pantryList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                    style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                         endIcon={<DeleteForeverIcon
                                                            sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                    </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Coffee</h3>
                                    <FormGroup>
                                        {coffeeList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                    style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                         endIcon={<DeleteForeverIcon
                                                            sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                    </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Drinks</h3>
                                    <FormGroup>
                                        {drinksList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                    style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                         endIcon={<DeleteForeverIcon
                                                            sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                    </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Freezer</h3>
                                    <FormGroup>
                                        {freezerList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                    style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                         endIcon={<DeleteForeverIcon
                                                            sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                    </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Snacks</h3>
                                    <FormGroup>
                                        {crispList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                    style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                         endIcon={<DeleteForeverIcon
                                                            sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                    </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Cleaning</h3>
                                    <FormGroup>
                                        {cleaningList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                    style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                         endIcon={<DeleteForeverIcon
                                                            sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                    </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Toiletries</h3>
                                    <FormGroup>
                                        {toilList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                    style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                         endIcon={<DeleteForeverIcon
                                                            sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                    </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Lunch</h3>
                                    <FormGroup>
                                        {lunchList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                    style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                         endIcon={<DeleteForeverIcon
                                                            sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                    </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                            <div className="p-3 mt-2">
                                <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                    <h3 className="">Dinner</h3>
                                    <FormGroup>
                                        {console.log(dinnerList)}
                                        {dinnerList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                    style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                         endIcon={<DeleteForeverIcon
                                                            sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                    </div>
                                            );
                                        })}
                                    </FormGroup>
                                </Box>
                            </div>
                        </Paper>
                    </Form>
                </Col>
                </Row>
            </Col>
            
        </Row>

        <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Meal
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Happy to add {inputShoppingListName} to your shopping lists?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Not yet</Button>
          <LoadingButton loading={isLoading} variant="contained" onClick={onSubmit} autoFocus>
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openErrorDialog}
        onClose={handleCloseErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Unable to create shopping list
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please make sure you have added items to your shopping list
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog}>Go back</Button>
        </DialogActions>
      </Dialog>

        <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={value===0? "Shopping List Created" : "Shopping List Updated"}
        key={vertical + horizontal}
      />
        </div>
);
 };