import '../css/browseEvents.css'; 
import "bootstrap/dist/css/bootstrap.min.css"
import Container from "react-bootstrap/esm/Container";
import {useEffect} from "react";
import React, { useState } from "react";
import Axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Paper from '@mui/material/Paper';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { bool } from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';






export const ViewShoppingLists = () => {
    const [shoppingListNames, setShoppingListNames] = useState([])
    const [selectedShoppingList, setSelectedShoppingList] = useState("")
    const [listAllItems, setListAllItems] = useState([])
    const [openDialog, setOpenDialog] = React.useState(false);
    const [home , setHome] = useState(false)
    const [fruitAndVeg, setFruitAndVeg] = useState(false)
    const [meats, setMeats] = useState(false)
    const [dairy, setDairy] = useState(false)
    const [baking, setBaking] = useState(false)
    const [pantry, setPantry] = useState(false)
    const [coffee, setCoffee] = useState(false)
    const [drinks, setDrinks] = useState(false)
    const [freezer, setFreezer] = useState(false)
    const [crisps, setCrisps] = useState(false)
    const [cleaning, setCleaning] = useState(false)
    const [toil, setToil] = useState(false)
    const [lunch, setLunch] = useState(false)
    const [dinner, setDinner] = useState(false)
    const [isLoading, setIsLoading] = React.useState(false);


    const handleSelectChange = (event) => {
        const tempSelect = event.target.value
        setSelectedShoppingList(tempSelect)
        setListAllItems([])
        setHome(false)
        setFruitAndVeg(false)
        setMeats(false)
        setDairy(false)
        setBaking(false)
        setPantry(false)
        setCoffee(false)
        setDrinks(false)
        setFreezer(false)
        setCrisps(false)
        setCleaning(false)
        setToil(false)
        setLunch(false)
        setDinner(false)
    };

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialog = () => {
        setOpenDialog(false);
      };

      const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });

      const { vertical, horizontal, open } = state;
    
      const handleClose = () => {
        setState({ ...state, open: false });
      };



    const loadShoppingList = () => {
            setListAllItems([])
            Axios.get('https://shopping.shanil.com/api/getselectedshoppinglist', {
                params: {selectedShoppingList}
            }).then((response) => {
                setListAllItems(response.data)
                runThrough()
                
            }).finally(() =>{
                
 

            });
      };

      const runThrough = () => {
        listAllItems.map((data) => {
            if (data.type === "home"){
                const homeTest = true;
                setHome(true)
            }
            if (data.type === "fruitAndVeg"){
                setFruitAndVeg(true)
            }
            if (data.type === "meats"){
                setMeats(true)
            }
            if (data.type === "dairy"){
                setDairy(true)
            }
            if (data.type === "baking"){
                setBaking(true)
            }
            if (data.type === "pantry"){
                setPantry(true)
            }
            if (data.type === "confectionaries"){
                setCoffee(true)
            }
            if (data.type === "drinks"){
                setDrinks(true)
            }
            if (data.type === "freezer"){
                setFreezer(true)
            }
            if (data.type === "Crisps"){
                setCrisps(true)
            }
            if (data.type === "cleaning"){
                setCleaning(true)
            }
            if (data.type === "Toiletries"){
                setToil(true)
            }
            if (data.type === "lunch"){
                setLunch(true)
            }
            if (data.type === "dinner"){
                setDinner(true)
            }
        })
      };

      const deleteShoppingList = (event) => {
        setIsLoading(true)
        Axios.delete('https://shopping.shanil.com/api/deleteshoppinglist', {
            params: {selectedShoppingList}
        }).then(() => {
            handleClickOpenDialog()
            setListAllItems([])
            setSelectedShoppingList("")
        }).finally(() => {
            handleCloseDialog()
            setState({ open: true, vertical:'bottom', horizontal: 'left' });
            Axios.get('https://shopping.shanil.com//api/getshoppinglists', {
            }).then((response) => {
            setShoppingListNames(response.data);
            setIsLoading(false)
            })
        });
      }

      const updateListStatus = (event) => {
        Axios.patch('https://shopping.shanil.com/api/updateshoppinglist', {
            status: event.target.checked,
            id: event.target.value,
            shoppingListName: selectedShoppingList
        });
      };

    useEffect(() => {
        Axios.get('https://shopping.shanil.com//api/getshoppinglists', {
        }).then((response) => {
        setShoppingListNames(response.data);
        })
    }, []);

    return (
        <div className="m-3">
            <Row>
                <Col xs={12} sm={12} md={12} lg={5}>
                <Form>
                    <h1 className=""></h1>
                    <Paper style={{width: "100%"}} elevation={6} sx={{ minWidth: 100, maxWidth: 1000 }}>
                        <div className="p-3">
                            <Box sx={{ minWidth: 100, maxWidth: 1000 }}>
                                <h3 className="p-2">View Shopping List</h3>
                                <div className="p-2">
                                    <Grid container spacing={2}>
                                        <Grid xs={12} sm={6} md={12} lg={10}>
                                            <InputLabel id="demo-multiple-chip-label">Select Shopping List</InputLabel>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={selectedShoppingList}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    onChange={handleSelectChange}
                                                >
                                                    {shoppingListNames.map ((item) => {
                                                        return (
                                                            <MenuItem key={item.shopping_list_name} value={item.shopping_list_name}>{item.shopping_list_name}</MenuItem>
                                                        )
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid style={{display: "flex", justifyContent: "flex-end"}} item xs={12} sm={12} md={12} lg={12}>
                                            <Button
                                                variant="contained"
                                                onClick={loadShoppingList}
                                                disabled={selectedShoppingList === "" ? true : false}
                                                 endIcon={<ShoppingCartCheckoutIcon />}>
                                                    {listAllItems.length === 0 ? "Open Shoping list" : "Show Aisle headings"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>
                        </div>
                    </Paper>
                </Form>
            </Col>
            <Col xs={12} sm={12} md={12} lg={5}>
                <Form>
                    {listAllItems.length === 0 ? null : <Paper display={false} className="mt-3" style={{width: "100%"}} elevation={6} sx={{ minWidth: 100, maxWidth: 1000 }}>
                        <div className="p-3">
                            <Grid style={{display: "flex", justifyContent: "flex-end"}} item xs={12} sm={12} md={12} lg={12}>
                                            <Button
                                                variant="outline"
                                                onClick={handleClickOpenDialog}
                                                 endIcon={<DeleteIcon
                                                    sx={{ color: '#ff0000' }} />}>
                                            </Button>
                                        </Grid>
                            <Box sx={{ minWidth: 100, maxWidth: 1000 }}>
                                <div>
                                    <FormGroup>
                                        {!home ? null :<h4 className='mt-3'>Home</h4>}
                                    {listAllItems.map ((item) => {
                                            if (item.type === "home")
                                            {
                                                return(
                                                <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}
                                        {!fruitAndVeg ? null :<h4 className='mt-3'>Fruit and Veg</h4>}
                                    {listAllItems.map ((item) => {
                                            
                                            if (item.type === "fruitAndVeg")
                                            {
                                                return(
                                                    <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}

                                    {!meats ? null :<h4 className='mt-3'>Meats</h4>}
                                    {listAllItems.map ((item) => {
                                            
                                            if (item.type === "meats")
                                            {
                                                return(
                                                    <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}
                                        {!dairy ? null :<h4 className='mt-3'>Dairy </h4>}
                                    {listAllItems.map ((item) => {
                                            
                                            if (item.type === "dairy")
                                            {
                                                return(
                                                    <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}
                                    {!baking ? null :<h4 className='mt-3'>Baking</h4>}
                                    {listAllItems.map ((item) => {
                                            
                                            if (item.type === "baking")
                                            {
                                                return(
                                                    <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}
                                    {!pantry ? null :<h4 className='mt-3'>Pantry</h4>}
                                    {listAllItems.map ((item) => {
                                            
                                            if (item.type === "pantry")
                                            {
                                                return(
                                                    <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}
                                    {!coffee ? null :<h4 className='mt-3'>Coffee</h4>}
                                    {listAllItems.map ((item) => {
                                            
                                            if (item.type === "confectionaries")
                                            {
                                                return(
                                                    <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}
                                    {!drinks ? null :<h4 className='mt-3'>Drinks</h4>}
                                    {listAllItems.map ((item) => {
                                            
                                            if (item.type === "drinks")
                                            {
                                                return(
                                                    <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}
                                    {!freezer ? null :<h4 className='mt-3'>Freezer</h4>}
                                    {listAllItems.map ((item) => {
                                            
                                            if (item.type === "freezer")
                                            {
                                                return(
                                                    <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}
                                    {!crisps ? null :<h4 className='mt-3'>Crisps</h4>}
                                    {listAllItems.map ((item) => {
                                            
                                            if (item.type === "Crisps")
                                            {
                                                return(
                                                    <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}
                                    {!cleaning ? null :<h4 className='mt-3'>Cleaning</h4>}
                                    {listAllItems.map ((item) => {
                                            
                                            if (item.type === "cleaning")
                                            {
                                                return(
                                                    <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}
                                    {!toil ? null :<h4 className='mt-3'>Toiletries</h4>}
                                    {listAllItems.map ((item) => {
                                            
                                            if (item.type === "Toiletries")
                                            {
                                                return(
                                                    <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}
                                    {!lunch ? null :<h4 className='mt-3'>Lunch</h4>}
                                    {listAllItems.map ((item) => {
                                            
                                            if (item.type === "lunch")
                                            {
                                                return(
                                                    <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}
                                    {!dinner ? null :<h4 className='mt-3'>Dinner</h4>}
                                    {listAllItems.map ((item) => {
                                            
                                            if (item.type === "dinner")
                                            {
                                                return(
                                                    <div>
                                                <FormControlLabel  control={<Checkbox value={item.id} onChange={updateListStatus} defaultChecked={item.is_ticked.data[0] === 0 ? false : true}/>} label={`${item.quantity} ${item.ingredients}`} />
                                                </div>
                                                );
                                            }
                                    })}
                                    </FormGroup>
                                </div>
                            </Box>
                        </div>
                    </Paper>}
                </Form>
            </Col>
        </Row>
        <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete shopping list
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this shopping list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <LoadingButton
             loading={isLoading}
             variant="contained"
             onClick={deleteShoppingList}
             autoFocus
             color="error">
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="Shopping list deleted"
        key={vertical + horizontal}
      />
    </div>
    );

            // defaultChecked={item.is_ticked.data[0] === 0 ? "false" : "true"}

};