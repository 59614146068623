import "bootstrap/dist/css/bootstrap.min.css"
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import "react-datetime/css/react-datetime.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState } from "react";
import {} from 'react-places-autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Unstable_Grid2';
import AddIcon from '@mui/icons-material/Add';
import Radio from '@mui/material/Radio';
import {useEffect} from "react";
import MenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DoneIcon from '@mui/icons-material/Done';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }






export const AddNewMeal = () => {
    const cardHeight = "300px";
    const [inputMealName, setInputMealName] = useState("");
    const [ingredientInput, setIngredientInput] = useState("");
    const [formInputType, setFormInputType] = useState("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [mealList, setMealList] = useState([]);
    const [selectedMealList, setSelectedMealList] = useState("");
    const [isDeleteLoading, setIsDeleteLoading] = React.useState(false);
    const [deleteFlag, setDeleteFlag] = React.useState(false);

    const tempHome = [];
    const tempFruit = [];
    const tempMeat = [];
    const tempDairy = [];
    const tempBaking = [];
    const tempPantry = [];
    const tempCoffee = [];
    const tempDrinks = [];
    const tempFrezzer = [];
    const tempCrisp = [];
    const tempCleaning = [];
    const tempToil = [];


    const [homeList, setHomeList] = useState([]);
    const [inputHome, setInputHome] = useState("");

    const [fruitList, setFruitList] = useState([]);
    const [inputFruit, setInputFruit] = useState("");

    const [meatList, setMeatList] = useState([]);
    const [inputMeat, setInputMeat] = useState("");

    const [dairyList, setDairyList] = useState([]);
    const [inputDairy, setInputDairy] = useState("");

    const [bakingList, setBakingList] = useState([]);
    const [inputBaking, setInputBaking] = useState("");

    const [pantryList, setPantryList] = useState([]);
    const [inputPantry, setInputPantry] = useState("");

    const [coffeeList, setCoffeeList] = useState([]);
    const [inputCoffee, setInputCoffee] = useState("");

    const [drinksList, setDrinksList] = useState([]);
    const [inputDrinks, setInputDrinks] = useState("");

    const [freezerList, setFreezerList] = useState([]);
    const [inputFreezer, setInputFreezer] = useState("");

    const [crispList, setCrispList] = useState([]);
    const [inputCrisp, setInputCrisp] = useState("");

    const [cleaningList, setCleaningList] = useState([]);
    const [inputCleaning, setInputCleaning] = useState("");

    const [toilList, setToilList] = useState([]);
    const [inputToil, setInputToil] = useState("");

    const [inputQuantity, setInputQuantity] = useState("");
    const [type, setType] = useState("");
    const [selectedMealType, setSelectedMealType] = useState("");

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });

      const { vertical, horizontal, open } = state;
    
      const handleClose = () => {
        setState({ ...state, open: false });
      };

    
    const handleChangeInputMealName = (event) => {
        setInputMealName(event.target.value);
    };

    const handleChangeTab = (event, newValue) => {
        setValue(newValue)
        setHomeList([])
        setFruitList([])
        setMeatList([])
        setDairyList([])
        setBakingList([])
        setPantryList([])
        setCoffeeList([])
        setDrinksList([])
        setFreezerList([])
        setCrispList([])
        setCleaningList([])
        setToilList([])
        setSelectedMealList("")
        handleCloseDialog()
        setIsLoading(false)
        setMealList([])
        updateMealList()
        setInputMealName("")
        setSelectedMealType("")
        setIsDeleteLoading(false)
      };

    const handleChangeInputIngredient = (event) => {
        setInputHome(event.target.value);
        setIngredientInput(event.target.value);
    };
    const handleChangeInputQuantity = (event) => {
        setInputQuantity(event.target.value);
    };
    const typeChange = (event) => {
        setType(event.target.value)
    };

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialog = () => {
        setOpenDialog(false);
      };

      const handleClickOpenErrorDialog = () => {
        setOpenErrorDialog(true);
      };
    
      const handleCloseErrorDialog = () => {
        setOpenErrorDialog(false);
      };

      const handleMealtypeSelectChange = (event) => {
        setSelectedMealType(event.target.value)
      };


    const addIngredient= () => {
        if (type === "home"){
            const id = homeList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type};
            const newHomeList = [...homeList, temp];
            setHomeList(newHomeList)
        }
        if (type === "fruitAndVeg"){
            const id = fruitList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type};
            const newFruitList = [...fruitList, temp];
            setFruitList(newFruitList)
        }
        if (type === "meats"){
            const id = meatList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type};
            const newMeatList = [...meatList, temp];
            setMeatList(newMeatList)
        }
        if (type === "dairy"){
            const id = dairyList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type};
            const newDairyList = [...dairyList, temp];
            setDairyList(newDairyList)
        }
        if (type === "baking"){
            const id = bakingList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type};
            const newBakingList = [...bakingList, temp];
            setBakingList(newBakingList)
        }
        if (type === "pantry"){
            const id = pantryList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type};
            const newPantryList = [...pantryList, temp];
            setPantryList(newPantryList)
        }
        if (type === "confectionaries"){
            const id = coffeeList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type};
            const newCoffeeList = [...coffeeList, temp];
            setCoffeeList(newCoffeeList)
        }
        if (type === "drinks"){
            const id = drinksList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type};
            const newDrinksList = [...drinksList, temp];
            setDrinksList(newDrinksList)
        }
        if (type === "freezer"){
            const id = freezerList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type};
            const newFreezerList = [...freezerList, temp];
            setFreezerList(newFreezerList)
        }
        if (type === "Crisps"){
            const id = crispList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type};
            const newCrispList = [...crispList, temp];
            setCrispList(newCrispList)
        }
        if (type === "cleaning"){
            const id = cleaningList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type};
            const newCleaningList = [...cleaningList, temp];
            setCleaningList(newCleaningList)
        }
        if (type === "Toiletries"){
            const id = toilList.length + 1;
            const temp = {id, ingredient:ingredientInput ,quantity:inputQuantity, type:type};
            const newToilList = [...toilList, temp];
            setToilList(newToilList)
        }
        setIngredientInput("")
        setInputQuantity("")
        setType("")
        
    };

    const handleSelectChange = (event) => {
        setSelectedMealList(event.target.value)
        setInputMealName(event.target.value)

        const select = event.target.value;

        Axios.get('https://shopping.shanil.com/api/getselectedingredients', {
            params: {select}
        }).then((response) =>{
            testFunc(response.data);
        });

        Axios.get('https://shopping.shanil.com/api/getmealtype', {
            params: {select}
        }).then((response) => {
            console.log(response.data[0].mealType)
            setSelectedMealType(response.data[0].mealType)
            // if (response.data[0].mealType === "lunch"){
            //     setSelectedMealType("lunch")
            // }
            // if (response.data[0].mealType === "lunch"){
            //     setSelectedMealType("lunch")
            // }
            // if (response.data[0].mealType === "lunch"){
            //     setSelectedMealType("lunch")
            // }
            // if (response.data[0].mealType === "lunch"){
            //     setSelectedMealType("lunch")
            // }
        })
    };

    const testFunc = (data) => {
        setHomeList([])
        setFruitList([])
        setMeatList([])
        setDairyList([])
        setBakingList([])
        setPantryList([])
        setCoffeeList([])
        setDrinksList([])
        setFreezerList([])
        setCrispList([])
        setCleaningList([])
        setToilList([])
        console.log(data)
        data.map((test) => {
            var type = test.type
            if (type === "home"){
                const id = tempHome.length + 1;
                const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                tempHome.push(temp);
                setHomeList(tempHome);
            }
            if (type === "fruitAndVeg"){
                const id = tempFruit.length + 1;
                const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                tempFruit.push(temp);
                setFruitList(tempFruit);
            }
            if (type === "meats"){
                const id = tempMeat.length + 1;
                const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                tempMeat.push(temp);
                setMeatList(tempMeat);
            }
            if (type === "dairy"){
                const id = tempDairy.length + 1;
                const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                tempDairy.push(temp);
                setDairyList(tempDairy);

            }
            if (type === "baking"){
                const id = tempBaking.length + 1;
                const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                tempBaking.push(temp);
                setBakingList(tempBaking);

            }
            if (type === "pantry"){
                const id = tempPantry.length + 1;
                const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                tempPantry.push(temp);
                setPantryList(tempPantry);

            }
            if (type === "confectionaries"){
                const id = tempCoffee.length + 1;
                const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                tempCoffee.push(temp);
                setCoffeeList(tempCoffee);

            }
            if (type === "drinks"){
                const id = tempDrinks.length + 1;
                const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                tempDrinks.push(temp);
                setDrinksList(tempDrinks);

            }
            if (type === "freezer"){
                const id = tempFrezzer.length + 1;
                const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                tempFrezzer.push(temp);
                setFreezerList(tempFrezzer);

            }
            if (type === "Crisps"){
                const id = tempCrisp.length + 1;
                const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                tempCrisp.push(temp);
                setCrispList(tempCrisp);

            }
            if (type === "cleaning"){
                const id = tempCleaning.length + 1;
                const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                tempCleaning.push(temp);
                setCleaningList(tempCleaning);

            }
            if (type === "Toiletries"){
                const id = tempToil.length + 1;
                const temp = {id, ingredient:test.ingredients, quantity:test.quantity, type:test.type};
                tempToil.push(temp);
                setToilList(tempToil);

            }
        })
    };

    const deleteIngredient = (id, type) => {


        if (type === "home"){
            setHomeList(homeList.filter((task) => task.id !== id));
        }
        if (type === "fruitAndVeg"){
            setFruitList(fruitList.filter((task) => task.id !== id));
        }
        if (type === "meats"){
            setMeatList(meatList.filter((task) => task.id !== id));
        }
        if (type === "dairy"){
            setDairyList(dairyList.filter((task) => task.id !== id));
        }
        if (type === "baking"){
            setBakingList(bakingList.filter((task) => task.id !== id));
        }
        if (type === "pantry"){
            setPantryList(pantryList.filter((task) => task.id !== id));
        }
        if (type === "confectionaries"){
            setCoffeeList(coffeeList.filter((task) => task.id !== id));
        }
        if (type === "drinks"){
            setDrinksList(drinksList.filter((task) => task.id !== id));
        }
        if (type === "freezer"){
            setFreezerList(freezerList.filter((task) => task.id !== id));
        }
        if (type === "Crisps"){
            setCrispList(crispList.filter((task) => task.id !== id));
        }
        if (type === "cleaning"){
            setCleaningList(cleaningList.filter((task) => task.id !== id));
        }
        if (type === "Toiletries"){
            setToilList(toilList.filter((task) => task.id !== id));
        }
    };


    useEffect(() => {
        Axios.get('https:shopping.shanil.com/api/getallmeals', {
        }).then((response) =>{
            // eslint-disable-next-line
            response.data.map((val) => {
                setMealList(mealList => [...mealList, val.mealName])      
                   
            })
        })
    },[]);


    const updateMealList = () => {

        Axios.get('https://shopping.shanil.com/api/getallmeals', {
        }).then((response) =>{
            // eslint-disable-next-line
            response.data.map((val) => {
                setMealList(mealList => [...mealList, val.mealName])      
                   
            })
        })
        
    };




    const onSubmit = () => {
        setIsLoading(true)
        var completeList = [...homeList, ...fruitList, ...meatList, ...dairyList, ...bakingList, ...pantryList, ...coffeeList, ...drinksList, ...freezerList, ...crispList, ...cleaningList, ...toilList]
        var completeListArray = completeList.map ((data) => {
            return [
                data.ingredient,
                data.quantity,
                data.type
            ];
        });

        if (completeListArray.length === 0){
            setIsLoading(false)
            handleCloseDialog()
            handleClickOpenErrorDialog()
        }else{
        Axios.post('https://shopping.shanil.com/api/createnewmeal', {
            mealName: inputMealName,
            completeList: completeListArray,
            mealType: selectedMealType
        }).then(() => {
                        
        }).finally(() => {
            setInputMealName("")
            setDeleteFlag(false)
            setState({ open: true, vertical:'bottom', horizontal: 'left' });
            setHomeList([])
            setFruitList([])
            setMeatList([])
            setDairyList([])
            setBakingList([])
            setPantryList([])
            setCoffeeList([])
            setDrinksList([])
            setFreezerList([])
            setCrispList([])
            setCleaningList([])
            setToilList([])
            setSelectedMealList("")
            handleCloseDialog()
            setIsLoading(false)
            setMealList([])
            setSelectedMealType("")
            updateMealList()
        })
    }
    };

    const updateMeal = () => {
        setIsLoading(true)
        Axios.delete("https://shopping.shanil.com/api/deletemeal", {
            params: {selectedMealList}
        }).then(() => {
            onSubmit()
        }).finally(() =>{
        })
    };

    const deleteMeal = () => {
        setIsDeleteLoading(true)
        Axios.delete("https://shopping.shanil.com/api/deletemeal", {
            params: {selectedMealList}
        }).then(() => {

            
        }).finally(() =>{
            setInputMealName("")
            setDeleteFlag(true)
            setState({ open: true, vertical:'bottom', horizontal: 'left' });
            setHomeList([])
            setFruitList([])
            setMeatList([])
            setDairyList([])
            setBakingList([])
            setPantryList([])
            setCoffeeList([])
            setDrinksList([])
            setFreezerList([])
            setCrispList([])
            setCleaningList([])
            setToilList([])
            setSelectedMealList("")
            handleCloseDialog()
            setIsLoading(false)
            setMealList([])
            updateMealList()
            setIsDeleteLoading(false)
        })
    }

    return (
        <div className="m-3">
            <Row>
            <Col  md={12} lg={4} xl={3}>
                <Form onSubmit={(event) => event.preventDefault()}>
                    <Paper style={{width: "100%"}} elevation={6} sx={{ minWidth: 150, maxWidth: 1000 }}>
                        <div className="p-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900, width:'100%' }}>
                            {value === 0 ? <h3 className="p-2">Create Meal</h3> : <h3 className="p-2">Edit Meal</h3> }
                            <Box sx={{borderTop:1,  borderBottom: 1, borderColor: 'divider' }}>
                            
        <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label="Create" {...a11yProps(0)} />
          <Tab label="Edit" {...a11yProps(1)} />
        </Tabs>
      </Box>
      
      <TabPanel value={value} index={0}>
                                <div className="p-2">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={10}>
                                            <TextField
                                                onChange={handleChangeInputMealName}
                                                required
                                                id="mealName"
                                                name="mealName"
                                                label="Meal Name"
                                                fullWidth
                                                autoComplete="given-name"
                                                variant="outlined"
                                                value={inputMealName}
                                            />
                                        </Grid>
                                        <Grid xs={12} sm={6} md={12} lg={12}>
                                            <InputLabel id="demo-multiple-chip-label">Meal type</InputLabel>
                                            <FormControl style={{width: '13rem'}}>
                                            <Select
                                                    value={selectedMealType}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    onChange={handleMealtypeSelectChange}
                                                    style={{height:'2.5rem'}}
                                                >
                                                            <MenuItem key={"lunch"} value={"lunch"}>Lunch</MenuItem>
                                                            <MenuItem key={"dinner"} value={"dinner"}>Dinner</MenuItem>
                                                            <MenuItem key={"lunchAndDinner"} value={"lunchAndDinner"}>Lunch and Dinner</MenuItem>
                                                            <MenuItem key={"other"} value={"other"}>Other</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <h4 className="mt-3">Add Ingredients</h4>
                                        </Grid>
                                        <Grid item xs={7} sm={9} md={7} lg={6}>
                                            <TextField
                                                onChange={handleChangeInputIngredient}                                        
                                                id="Ingredient"
                                                name="Ingredient"
                                                label="Ingredient"
                                                fullWidth
                                                autoComplete="given-name"
                                                variant="outlined"
                                                value={ingredientInput}
                                            />
                                        </Grid>
                                        <Grid item xs={5} sm={3} md={5} lg={4}>
                                            <TextField     
                                                onChange={handleChangeInputQuantity}                                   
                                                id="Quantity"
                                                name="Quantity"
                                                label="Quantity"
                                                fullWidth
                                                autoComplete="given-name"
                                                variant="outlined"
                                                value={inputQuantity}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={10} md={12} lg={12}>
                                            <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={typeChange}
                                                value={type}
                                            >
                                                <FormControlLabel value="home" control={<Radio />} label="Home" />
                                                <FormControlLabel value="fruitAndVeg" control={<Radio />} label="Fruit and Veg" />
                                                <FormControlLabel value="meats" control={<Radio />} label="Meats" />
                                                <FormControlLabel value="dairy" control={<Radio />} label="Dairy" />
                                                <FormControlLabel value="baking" control={<Radio />} label="Baking" />
                                                <FormControlLabel value="pantry" control={<Radio />} label="Pantry Items" />
                                                <FormControlLabel value="confectionaries" control={<Radio />} label="Coffee" />
                                                <FormControlLabel value="drinks" control={<Radio />} label="Drinks" />
                                                <FormControlLabel value="freezer" control={<Radio />} label="Freezer Food" />
                                                <FormControlLabel value="Crisps" control={<Radio />} label="crisps" />
                                                <FormControlLabel value="cleaning" control={<Radio />} label="Cleaning" />
                                                <FormControlLabel value="Toiletries" control={<Radio />} label="toiletries" />
                                            </RadioGroup>
                                        </Grid>
                                        <Grid style={{display: "flex", justifyContent: "flex-end"}} item xs={12} sm={2} md={12} lg={12}>
                                            <Button
                                                onClick={addIngredient}
                                                variant="contained"
                                                disabled={(type === "" || ingredientInput === "" || inputQuantity === "") ? true : false}
                                                value={formInputType}
                                                 endIcon={<AddIcon />}>
                                                    Add
                                            </Button>
                                        </Grid>

                                        <Grid className="mt-2" style={{display: "flex", justifyContent: "flex-end"}} item xs={12} sm={12} md={12} lg={12}>
                                        <Button
                                                variant="contained"
                                                color="success"
                                                className="p-3"
                                                type="submit"
                                                onClick={handleClickOpenDialog}
                                                disabled={inputMealName === "" || selectedMealType ==="" ? true : false}
                                                 endIcon={<DoneIcon />}>
                                                    Submit Meal
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                <div className="p-2">
                                    <Grid container spacing={2}>


                                    <Grid xs={12} sm={6} md={12} lg={12}>
                                            <InputLabel id="demo-multiple-chip-label">Select meal to edit</InputLabel>
                                            <FormControl fullWidth>
                                            <Select
                                                    value={selectedMealList}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    onChange={handleSelectChange}
                                                >
                                                    {mealList.map ((item) => {
                                                        return (
                                                            <MenuItem key={item} value={item}>{item}</MenuItem>
                                                        )
                                                    })}
                                                    
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <InputLabel id="demo-multiple-chip-label">Edit name</InputLabel>

                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <TextField
                                                onChange={handleChangeInputMealName}
                                                required
                                                id="mealName"
                                                name="mealName"
                                                label="Meal Name"
                                                fullWidth
                                                autoComplete="given-name"
                                                variant="outlined"
                                                value={inputMealName}
                                            />
                                        </Grid>
                                        <Grid xs={12} sm={6} md={12} lg={12}>
                                            <InputLabel id="demo-multiple-chip-label">Meal type</InputLabel>
                                            <FormControl style={{width: '13rem'}}>
                                            <Select
                                                    value={selectedMealType}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    onChange={handleMealtypeSelectChange}
                                                    style={{height:'2.5rem'}}
                                                >
                                                            <MenuItem key={"lunch"} value={"lunch"}>Lunch</MenuItem>
                                                            <MenuItem key={"dinner"} value={"dinner"}>Dinner</MenuItem>
                                                            <MenuItem key={"lunchAndDinner"} value={"lunchAndDinner"}>Lunch and Dinner</MenuItem>
                                                            <MenuItem key={"other"} value={"other"}>Other</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <h4 className="mt-3">Add Ingredients</h4>
                                        </Grid>
                                        <Grid item xs={7} sm={9} md={7} lg={6}>
                                            <TextField
                                                onChange={handleChangeInputIngredient}                                        
                                                id="Ingredient"
                                                name="Ingredient"
                                                label="Ingredient"
                                                fullWidth
                                                autoComplete="given-name"
                                                variant="outlined"
                                                value={ingredientInput}
                                            />
                                        </Grid>
                                        <Grid item xs={5} sm={3} md={5} lg={4}>
                                            <TextField     
                                                onChange={handleChangeInputQuantity}                                   
                                                id="Quantity"
                                                name="Quantity"
                                                label="Quantity"
                                                fullWidth
                                                autoComplete="given-name"
                                                variant="outlined"
                                                value={inputQuantity}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={10} md={12} lg={12}>
                                            <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={typeChange}
                                                value={type}
                                            >
                                                <FormControlLabel value="home" control={<Radio />} label="Home" />
                                                <FormControlLabel value="fruitAndVeg" control={<Radio />} label="Fruit and Veg" />
                                                <FormControlLabel value="meats" control={<Radio />} label="Meats" />
                                                <FormControlLabel value="dairy" control={<Radio />} label="Dairy" />
                                                <FormControlLabel value="baking" control={<Radio />} label="Baking" />
                                                <FormControlLabel value="pantry" control={<Radio />} label="Pantry Items" />
                                                <FormControlLabel value="confectionaries" control={<Radio />} label="Coffee" />
                                                <FormControlLabel value="drinks" control={<Radio />} label="Drinks" />
                                                <FormControlLabel value="freezer" control={<Radio />} label="Freezer Food" />
                                                <FormControlLabel value="Crisps" control={<Radio />} label="crisps" />
                                                <FormControlLabel value="cleaning" control={<Radio />} label="Cleaning" />
                                                <FormControlLabel value="Toiletries" control={<Radio />} label="toiletries" />
                                            </RadioGroup>
                                        </Grid>
                                        <Grid style={{display: "flex", justifyContent: "flex-end"}} item xs={12} sm={2} md={12} lg={12}>
                                            <Button
                                                onClick={addIngredient}
                                                variant="contained"
                                                disabled={(type === "" || ingredientInput === "" || inputQuantity === "") ? true : false}
                                                value={formInputType}
                                                 endIcon={<AddIcon />}>
                                                    Add
                                            </Button>
                                        </Grid>


                                        <Grid className="mt-2" style={{display: "flex", justifyContent: "flex-start"}} item xs={6} sm={6} md={6} lg={6}>

                                            <LoadingButton
                                                loading={isDeleteLoading}
                                                variant="contained"
                                                onClick={deleteMeal}
                                                autoFocus
                                                style={{right:0}}
                                                className="p-3"
                                                color="error"
                                                disabled={inputMealName === "" ? true : false}
                                                endIcon={<DeleteIcon/>}>
                                                Delete
                                            </LoadingButton>



                                        </Grid>

                                        <Grid className="mt-2" style={{display: "flex", justifyContent: "flex-end"}} item xs={6} sm={6} md={6} lg={6}>


                                            <LoadingButton
                                                loading={isLoading}
                                                variant="contained"
                                                onClick={updateMeal}
                                                autoFocus
                                                className="p-3"
                                                color="success"
                                                disabled={inputMealName === "" || selectedMealType ==="" || selectedMealList === "" ? true : false}
                                                endIcon={<DoneIcon />}>
                                                Update
                                            </LoadingButton>
                                            </Grid>

                                    </Grid>
                                </div>
                                </TabPanel>
                            </Box>
                        </div>
                    </Paper>
                </Form>
                </Col>
                <Col md={12} lg={8} xl={9}>
                    <Row>
                        <Col sm={6} md={4} lg={4}>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                        <div className="p-3 mt-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                                
                                <h3 className="">Home</h3>
                                <FormGroup>
                                        {homeList.map((item) => {
                                            return(
                                        <div style={{display:'flex'}}>
                                        <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                            <Button
                                            style={{justifyContent:'flex-end'}}
                                                variant="outline"
                                                onClick={() => deleteIngredient(item.id, item.type)}
                                                 endIcon={<DeleteForeverIcon
                                                    sx={{ color: '#d4d1c9' }} />}>
                                            </Button>
                                            </div>
                                            );
                                        })}
                                    </FormGroup>
                            </Box>
                        </div>
                    </Paper>
                        </Col>
                        <Col sm={6} md={4} lg={4}>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                        <div className="p-3 mt-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                            <h3 className="">Fruit and Veg</h3>
                            <FormGroup>
                                        {fruitList.map((item) => {
                                            return(
                                        <div style={{display:'flex'}}>
                                        <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                            <Button
                                            style={{justifyContent:'flex-end'}}
                                                variant="outline"
                                                onClick={() => deleteIngredient(item.id, item.type)}
                                                 endIcon={<DeleteForeverIcon
                                                    sx={{ color: '#d4d1c9' }} />}>
                                            </Button>
                                            </div>
                                            );
                                        })}
                                    </FormGroup>
                            </Box>
                        </div>
                    </Paper>
                        </Col>
                        <Col sm={6} md={4} lg={4}>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                        <div className="p-3 mt-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                            <h3 className="">Meats</h3>
                                {meatList.map((item) => {
                                            return(
                                                <div style={{display:'flex'}}>
                                                    <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                                    <Button
                                                        style={{justifyContent:'flex-end'}}
                                                        variant="outline"
                                                        onClick={() => deleteIngredient(item.id, item.type)}
                                                        endIcon={<DeleteForeverIcon
                                                        sx={{ color: '#d4d1c9' }} />}>
                                                    </Button>
                                                </div>
                                            )
                                        })}
                            </Box>
                        </div>
                    </Paper>
                        </Col>
                        <Col sm={6} md={4} lg={4}>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                        <div className="p-3 mt-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                            <h3 className="">Dairy</h3>
                            <FormGroup>
                                        {dairyList.map((item) => {
                                            return(
                                        <div style={{display:'flex'}}>
                                        <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                            <Button
                                            style={{justifyContent:'flex-end'}}
                                                variant="outline"
                                                onClick={() => deleteIngredient(item.id, item.type)}
                                                 endIcon={<DeleteForeverIcon
                                                    sx={{ color: '#d4d1c9' }} />}>
                                            </Button>
                                            </div>
                                            );
                                        })}
                                    </FormGroup>
                            </Box>
                        </div>
                    </Paper>
                        </Col>
                        <Col sm={6} md={4} lg={4}>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                        <div className="p-3 mt-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                            <h3 className="">Baking</h3>
                            <FormGroup>
                                        {bakingList.map((item) => {
                                            return(
                                        <div style={{display:'flex'}}>
                                        <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                            <Button
                                            style={{justifyContent:'flex-end'}}
                                                variant="outline"
                                                onClick={() => deleteIngredient(item.id, item.type)}
                                                 endIcon={<DeleteForeverIcon
                                                    sx={{ color: '#d4d1c9' }} />}>
                                            </Button>
                                            </div>
                                            );
                                        })}
                                    </FormGroup>
                            </Box>
                        </div>
                    </Paper>
                        </Col>
                        <Col sm={6} md={4} lg={4}>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                        <div className="p-3 mt-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                            <h3 className="">Pantry</h3>
                            <FormGroup>
                                        {pantryList.map((item) => {
                                            return(
                                        <div style={{display:'flex'}}>
                                        <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                            <Button
                                            style={{justifyContent:'flex-end'}}
                                                variant="outline"
                                                onClick={() => deleteIngredient(item.id, item.type)}
                                                 endIcon={<DeleteForeverIcon
                                                    sx={{ color: '#d4d1c9' }} />}>
                                            </Button>
                                            </div>
                                            );
                                        })}
                                    </FormGroup>
                            </Box>
                        </div>
                    </Paper>
                        </Col>
                        <Col sm={6} md={4} lg={4}>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                        <div className="p-3 mt-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                            <h3 className="">Coffee</h3>
                            <FormGroup>
                                        {coffeeList.map((item) => {
                                            return(
                                        <div style={{display:'flex'}}>
                                        <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                            <Button
                                            style={{justifyContent:'flex-end'}}
                                                variant="outline"
                                                onClick={() => deleteIngredient(item.id, item.type)}
                                                 endIcon={<DeleteForeverIcon
                                                    sx={{ color: '#d4d1c9' }} />}>
                                            </Button>
                                            </div>
                                            );
                                        })}
                                    </FormGroup>
                            </Box>
                        </div>
                    </Paper>
                        </Col>
                        <Col sm={6} md={4} lg={4}>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                        <div className="p-3 mt-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                            <h3 className="">Drinks</h3>
                            <FormGroup>
                                        {drinksList.map((item) => {
                                            return(
                                        <div style={{display:'flex'}}>
                                        <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                            <Button
                                            style={{justifyContent:'flex-end'}}
                                                variant="outline"
                                                onClick={() => deleteIngredient(item.id, item.type)}
                                                 endIcon={<DeleteForeverIcon
                                                    sx={{ color: '#d4d1c9' }} />}>
                                            </Button>
                                            </div>
                                            );
                                        })}
                                    </FormGroup>
                            </Box>
                        </div>
                    </Paper>
                        </Col>
                        <Col sm={6} md={4} lg={4}>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                        <div className="p-3 mt-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                            <h3 className="">Freezer Foods</h3>
                            <FormGroup>
                                        {freezerList.map((item) => {
                                            return(
                                        <div style={{display:'flex'}}>
                                        <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                            <Button
                                            style={{justifyContent:'flex-end'}}
                                                variant="outline"
                                                onClick={() => deleteIngredient(item.id, item.type)}
                                                 endIcon={<DeleteForeverIcon
                                                    sx={{ color: '#d4d1c9' }} />}>
                                            </Button>
                                            </div>
                                            );
                                        })}
                                    </FormGroup>
                            </Box>
                        </div>
                    </Paper>
                        </Col>
                        <Col sm={6} md={4} lg={4}>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                        <div className="p-3 mt-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                            <h3 className="">Snacks</h3>
                            <FormGroup>
                                        {crispList.map((item) => {
                                            return(
                                        <div style={{display:'flex'}}>
                                        <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                            <Button
                                            style={{justifyContent:'flex-end'}}
                                                variant="outline"
                                                onClick={() => deleteIngredient(item.id, item.type)}
                                                 endIcon={<DeleteForeverIcon
                                                    sx={{ color: '#d4d1c9' }} />}>
                                            </Button>
                                            </div>
                                            );
                                        })}
                                    </FormGroup>
                            </Box>
                        </div>
                    </Paper>
                        </Col>
                        <Col sm={6} md={4} lg={4}>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                        <div className="p-3 mt-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                            <h3 className="">Cleaning</h3>
                            <FormGroup>
                                        {cleaningList.map((item) => {
                                            return(
                                        <div style={{display:'flex'}}>
                                        <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                            <Button
                                            style={{justifyContent:'flex-end'}}
                                                variant="outline"
                                                onClick={() => deleteIngredient(item.id, item.type)}
                                                 endIcon={<DeleteForeverIcon
                                                    sx={{ color: '#d4d1c9' }} />}>
                                            </Button>
                                            </div>
                                            );
                                        })}
                                    </FormGroup>
                            </Box>
                        </div>
                    </Paper>
                        </Col>
                        <Col sm={6} md={4} lg={4}>
                        <Paper style={{minHeight:'5rem'}} elevation={6} sx={{ minWidth: 100, maxWidth: 900 }}>
                        <div className="p-3 mt-2">
                            <Box sx={{ minWidth: 275, maxWidth: 900 }}>
                            <h3 className="">Toiletries</h3>
                            <FormGroup>
                                        {toilList.map((item) => {
                                            return(
                                        <div style={{display:'flex'}}>
                                        <InputLabel style={{width:'100%'}} id="demo-multiple-chip-label">{item.quantity} {item.ingredient}</InputLabel>
                                            <Button
                                            style={{justifyContent:'flex-end'}}
                                                variant="outline"
                                                onClick={() => deleteIngredient(item.id, item.type)}
                                                 endIcon={<DeleteForeverIcon
                                                    sx={{ color: '#d4d1c9' }} />}>
                                            </Button>
                                            </div>
                                            );
                                        })}
                                    </FormGroup>
                            </Box>
                        </div>
                    </Paper>
                        </Col>
                    </Row>
                </Col>
                
            </Row>
            <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Meal
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Happy to add {inputMealName} to your list of meals?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Not yet</Button>
          <LoadingButton loading={isLoading} variant="contained" onClick={onSubmit} autoFocus>
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openErrorDialog}
        onClose={handleCloseErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Unable to add new meal
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please make sure you have added ingredients for {inputMealName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog}>Go back</Button>
        </DialogActions>
      </Dialog>
            <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={value === 0 ? "Meal created" : deleteFlag === true ? "Meal deleted" : "Meal updated"}
        key={vertical + horizontal}
      />
    </div>
    );
};